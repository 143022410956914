import React, { useState, useEffect } from "react";
import {
  Tab,
  Tabs,
  Grid,
  useMediaQuery,
  Box,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import SubscriptionCards from "./subscriptionCards";
import PropTypes from "prop-types";
import useFetch from "use-http";

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#FF6035",
    "& > span": {
      maxWidth: 50,
      width: "100%",
      backgroundColor: "#FF6035",
    },
  },
  flexContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    minWidth: "60px !important",
    textTransform: "none",
    color: "#080808e3",
    marginRight: 10,
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "14px",
  },
  selected: {
    color: "#09A9BB",
  },
}))((props) => <Tab disableRipple {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pt={2}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function SubscriptionPlans({ courseId }) {
  const classes = useStyles();
  const theme = useTheme();
  const { get } = useFetch(`${process.env.REACT_APP_API_URL}`);
  // const { get } = useFetch(`http://localhost:8000/api/v1`);
  const [value, setValue] = useState(0);
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getData = async () => {
    setLoading(true);
    try {
      let res = await get(`/static/getAllSubscriptionPlans`);
      setPlans(res?.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Grid
      container
      className={classes.container}
      style={{ padding: isMobile && "20px 10px 70px 10px" }}
    >
      <Grid item xs={12}>
        <Typography
          variant="h1"
          className={classes.heading}
          style={{
            fontSize: isMobile && "24px",
            lineHeight: "33px",
            padding: isMobile ? "10px 16px 0px 16px" : "20px 16px 0px 0px",
          }}
        >
          Membership plans
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="styled tabs example"
          variant="scrollable"
          scrollButtons="auto"
        >
          <StyledTab label={"Monthly Billing"} />
          <StyledTab label={"Annual Billing"} />
        </StyledTabs>
      </Grid>
      <Grid item xs={12}>
        <TabPanel value={value} index={0}>
          {loading ? (
            <div className={classes.loader}>
              <CircularProgress style={{ color: "#0EB2EF" }} />
            </div>
          ) : plans?.length > 0 ? (
            <SubscriptionCards
              courseId={courseId}
              annual={false}
              plans={plans}
            />
          ) : (
            <div className={classes.loader}>No Data</div>
          )}
        </TabPanel>

        <TabPanel value={value} index={1}>
          {loading ? (
            <div className={classes.loader}>
              <CircularProgress style={{ color: "#0EB2EF" }} />
            </div>
          ) : plans?.length > 0 ? (
            <SubscriptionCards
              courseId={courseId}
              annual={true}
              plans={plans}
            />
          ) : (
            <div className={classes.loader}>No Data</div>
          )}
        </TabPanel>
      </Grid>
    </Grid>
  );
}

export default SubscriptionPlans;

const useStyles = makeStyles((theme) => ({
  container: {
    // padding: 25,
  },
  backArrow: {
    cursor: "pointer",
  },
  loader: {
    height: "80vh",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  heading: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "32px",
    lineHeight: "56px",
    color: "#000",
    textAlign: "center",
    letterSpacing: "0.05em",
    padding: "0 0 30px 0px",
  },
}));
