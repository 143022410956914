import React from "react";
import Layout from "../components/layout/index";
import Subscriptions from "../components/home/subscriptions";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, useMediaQuery } from "@material-ui/core";

export default function About({ data, location }) {
  const params = new URLSearchParams(location.search);
  const courseId = params.get("course");
  const classes = useStyles();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const seoData = {
    title: data?.strapiSubscriptionPage?.seo_title,
    description: data?.strapiSubscriptionPage?.seo_description,
    keyword: data?.strapiSubscriptionPage?.seo_tags,
    url: "subscription-plans",
  };

  return (
    <Layout seoData={seoData} page={"privacy"}>
      <Grid
        container
        justify="center"
        style={{ marginTop: isMobile ? "15%" : "5%" }}
      >
        <Grid item xs={12}>
          <Subscriptions courseId={courseId} />
        </Grid>
      </Grid>
    </Layout>
  );
}

// export default IndexPage

const useStyles = makeStyles((theme) => ({
  description: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontSize: "20px",
    // lineHeight: "151.69%",
    // letterSpacing: "0.045em",
    textAlign: "justify",

    padding: "14px 35px",
  },
}));

export const query = graphql`
  {
    strapiSubscriptionPage {
      seo_description
      seo_tags
      seo_title
    }
  }
`;
