import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  CheckCircleOutlineRounded,
  TrendingUp,
  CancelOutlined,
} from "@material-ui/icons";
import {
  Grid,
  Typography,
  CardContent,
  Card,
  IconButton,
  Button,
  useMediaQuery,
  Chip,
} from "@material-ui/core";
import subscriptionCardIcon1 from "../../../assets/img/subscriptionCardIcon1.svg";
import subscriptionCardIcon2 from "../../../assets/img/subscriptionCardIcon2.svg";
import subscriptionCardIcon3 from "../../../assets/img/subscriptionCardIcon3.svg";
import subscriptionCardIcon4 from "../../../assets/img/subscriptionCardIcon4.svg";
import useFetch from "use-http";
import { Link } from "gatsby";
import Truncate from "react-truncate";
import { RELATE_APP_BASE_URL } from "../../../utils/constants";

export default function SubscriptionCard(props) {
  const theme = useTheme();
  const classes = useStyles();
  const { get, response } = useFetch(`${process.env.REACT_APP_API_URL}`);
  const [configs, setConfigs] = useState(null);
  let isMobile = useMediaQuery(theme.breakpoints.up("sm")) ? false : true;
  const { color, background, plan, ind, annual, courseId } = props;

  const [totalAvailableCourses, setTotalAvailableCourses] = useState(null);
  const [totalAvailableSessions, setTotalAvailableSessions] = useState(null);
  const [totalAvailableRetreats, setTotalAvailableRetreats] = useState(null);
  const [
    totalAvailableTeacherTrainings,
    setAvailableTeacherTrainings,
  ] = useState(null);

  useEffect(() => {
    setTotalAvailableCourses(
      parseInt(plan.availableCourses) + parseInt(plan.availableTeacherCourses)
    );
    setTotalAvailableSessions(
      parseInt(plan.availableSessions) + parseInt(plan.availableTeacherSessions)
    );
    setTotalAvailableRetreats(
      props?.annual
        ? parseInt(plan.annualRetreatDiscount)
        : parseInt(plan.monthlyRetreatDiscount)
    );
    setAvailableTeacherTrainings(
      props?.annual
        ? parseInt(plan.annualTeacherTrainingDiscount)
        : parseInt(plan.monthlyTeacherTrainingDiscount)
    );
  }, [plan]);
  let imageIcon = [
    subscriptionCardIcon1,
    subscriptionCardIcon1,
    subscriptionCardIcon2,
    subscriptionCardIcon3,
    subscriptionCardIcon4,
  ];

  useEffect(() => {
    async function fetchConfigs() {
      const _configs = await get("/config/");
      if (response.ok) {
        let obj = {};
        for (const item of _configs?.data) {
          obj = { ...obj, [item.key]: item.value };
        }
        setConfigs(obj);
      } else {
        fetchConfigs();
      }
    }

    fetchConfigs();
  }, []);

  return (
    <>
      {plan?.attractTag && ((plan?.name !== "Free" && annual) || !annual) && (
        <Grid item xs={12} style={{ position: "relative" }}>
          <Chip
            size="small"
            icon={<TrendingUp />}
            label={plan?.attractTag}
            color="secondary"
            classes={{ colorSecondary: classes.tagClr }}
            className={classes.tagStyle}
          />
        </Grid>
      )}
      {(plan?.name !== "Free" && annual) || !annual ? (
        <Card className={classes.root}>
          <CardContent style={{ padding: 0 }}>
            <Grid
              item={12}
              className={clsx(classes.header, background)}
              container
            >
              {plan?.name !== "Free" && (
                <Grid item>
                  <IconButton className={classes.iconContainer}>
                    <img src={imageIcon[0]} alt="" loading="lazy" />
                    {/* {plan?.icon} */}
                  </IconButton>
                </Grid>
              )}
              <Grid item>
                <Typography
                  variant="h2"
                  className={classes.headerTxt}
                  style={{ fontSize: isMobile ? "20px" : "24px" }}
                >
                  {plan?.name}
                </Typography>
              </Grid>
            </Grid>

            <Grid container justify="center">
              {
                // plan?.name === 'Free' ? <Grid item={12} className={clsx(classes.priceContainer)} container spacing={1}>
                //     <Typography className={classes.freeTxt}> Free </Typography>
                // </Grid> :
                <Grid
                  item={12}
                  className={clsx(classes.priceContainer)}
                  container
                  spacing={1}
                >
                  <Grid item xs={12} container justify="center">
                    {plan?.name !== "Free" && (
                      <Grid item>
                        <Typography
                          variant="h2"
                          className={classes.priceTxt}
                          style={{ fontSize: isMobile ? "30px" : "36px" }}
                        >
                          {configs?.currency}
                          {annual ? plan?.annualPrice : plan?.price}
                        </Typography>
                      </Grid>
                    )}
                    <Grid item>
                      <Typography
                        variant="h2"
                        className={clsx(classes.discountPriceTxt, color)}
                        style={{ fontSize: isMobile ? "30px" : "36px" }}
                      >
                        {configs?.currency}
                        {annual
                          ? plan?.annualDiscountedPrice
                          : plan?.discounted}
                        <sub className={classes.limitTxt}>
                          {annual ? "ANNUAL" : "MONTH"}
                        </sub>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    className={clsx(
                      classes.priceDescriptionContainer,
                      background
                    )}
                  >
                    <Typography variant="h2" className={classes.priceDesTxt}>
                      {plan?.priceTagline}
                    </Typography>
                  </Grid>
                </Grid>
              }
              <Grid item xs={12} container className={classes.benefitContainer}>
                {plan.isActiveOmidCoursesTitle == true ? (
                  <Grid item xs={10} container>
                    <Grid item xs={2}>
                      {totalAvailableCourses == "0" ? (
                        <CancelOutlined className={classes.crossIcon} />
                      ) : (
                        <CheckCircleOutlineRounded className={color} />
                      )}
                    </Grid>
                    <Grid item xs={10}>
                      <Truncate lines={1} ellipsis={<span>...</span>}>
                        {totalAvailableCourses + " " + plan?.omidCoursesTitle}
                      </Truncate>
                    </Grid>
                  </Grid>
                ) : null}
                {plan.isActiveOmidSessionsTitle == true ? (
                  <Grid item xs={10} container>
                    <Grid item xs={2}>
                      {totalAvailableSessions == "0" ? (
                        <CancelOutlined className={classes.crossIcon} />
                      ) : (
                        <CheckCircleOutlineRounded className={color} />
                      )}
                    </Grid>
                    <Grid item xs={10}>
                      <Truncate lines={1} ellipsis={<span>...</span>}>
                        {totalAvailableSessions + " " + plan?.omidSessionsTitle}
                      </Truncate>
                    </Grid>
                  </Grid>
                ) : null}
                {plan.isActiveRetreatDiscountTitle == true ? (
                  <Grid item xs={10} container>
                    <Grid item xs={2}>
                      {totalAvailableRetreats == "0" ? (
                        <CancelOutlined className={classes.crossIcon} />
                      ) : (
                        <CheckCircleOutlineRounded className={color} />
                      )}
                    </Grid>
                    <Grid item xs={10}>
                      <Truncate lines={1} ellipsis={<span>...</span>}>
                        {totalAvailableRetreats +
                          "% " +
                          plan?.retreatDiscountTitle}
                      </Truncate>
                    </Grid>
                  </Grid>
                ) : null}
                {plan.isActiveTeacherTrainingDiscountTitle == true ? (
                  <Grid item xs={10} container>
                    <Grid item xs={2}>
                      {totalAvailableTeacherTrainings == "0" ? (
                        <CancelOutlined className={classes.crossIcon} />
                      ) : (
                        <CheckCircleOutlineRounded className={color} />
                      )}
                    </Grid>
                    <Grid item xs={10}>
                      <Truncate lines={1} ellipsis={<span>...</span>}>
                        {totalAvailableTeacherTrainings +
                          "% " +
                          plan?.teacherTrainingDiscountTitle}
                      </Truncate>
                    </Grid>
                  </Grid>
                ) : null}
                {plan.isActiveCourseDiscountTitle == true ? (
                  <Grid item xs={10} container>
                    <Grid item xs={2}>
                      {plan?.omidDiscount == "0" ? (
                        <CancelOutlined className={classes.crossIcon} />
                      ) : (
                        <CheckCircleOutlineRounded className={color} />
                      )}
                    </Grid>
                    <Grid item xs={10}>
                      <Truncate lines={1} ellipsis={<span>...</span>}>
                        {plan?.omidDiscount + "% " + plan?.courseDiscountTitle}
                      </Truncate>
                    </Grid>
                  </Grid>
                ) : null}
                {plan.isActiveSessionDiscountTitle == true ? (
                  <Grid item xs={10} container>
                    <Grid item xs={2}>
                      {plan?.omidDiscount == "0" ? (
                        <CancelOutlined className={classes.crossIcon} />
                      ) : (
                        <CheckCircleOutlineRounded className={color} />
                      )}
                    </Grid>
                    <Grid item xs={10}>
                      <Truncate lines={1} ellipsis={<span>...</span>}>
                        {plan?.omidDiscount + "% " + plan?.sessionDiscountTitle}
                      </Truncate>
                    </Grid>
                  </Grid>
                ) : null}
                {plan?.noOfFreeCourses > 0 && (
                  <Grid item xs={10} container>
                    <Grid item xs={2}>
                      {plan?.noOfFreeCourses == "0" ? (
                        <CancelOutlined className={classes.crossIcon} />
                      ) : (
                        <CheckCircleOutlineRounded className={color} />
                      )}
                    </Grid>
                    <Grid item xs={10}>
                      <Truncate lines={1} ellipsis={<span>...</span>}>
                        {plan?.noOfFreeCourses + " " + plan?.freeCourseTitle}
                      </Truncate>
                    </Grid>
                  </Grid>
                )}

                {/* <Grid item xs={10} container >
                                <Grid item xs={2}>
                                    {plan?.availableTeacherCourses === '0' ? <CancelOutlined className={classes.crossIcon}/> :  <CheckCircleOutlineRounded className={color} />}
                                </Grid>
                                <Grid item xs={10}>
                                    <Truncate lines={1} ellipsis={<span>...</span>} >
                                        {plan?.availableTeacherCourses+" "+ plan?.teacherCoursesTitle}
                                    </Truncate>
                                </Grid>
                            </Grid>
                            <Grid item xs={10} container >
                                <Grid item xs={2}>
                                    {plan?.availableTeacherSessions === '0' ? <CancelOutlined className={classes.crossIcon}/> :  <CheckCircleOutlineRounded className={color} />}
                                </Grid>
                                <Grid item xs={10}>
                                    <Truncate lines={1} ellipsis={<span>...</span>} >
                                        {plan?.availableTeacherSessions+" "+ plan?.teacherSessionsTitle}
                                    </Truncate>
                                </Grid>
                            </Grid>

                            <Grid item xs={10} container >
                                <Grid item xs={2}>
                                    {plan?.availableTeacherRetreats === '0' ? <CancelOutlined className={classes.crossIcon}/> :  <CheckCircleOutlineRounded className={color} />}
                                </Grid>
                                <Grid item xs={10}>
                                    <Truncate lines={1} ellipsis={<span>...</span>} >
                                        {plan?.availableTeacherRetreats+" "+ plan?.teacherRetreatsTitle}
                                    </Truncate>
                                </Grid>
                            </Grid>


                            <Grid item xs={10} container >
                                <Grid item xs={2}>
                                    {plan?.availableTeachersTeacherTrainings === '0' ? <CancelOutlined className={classes.crossIcon}/> :  <CheckCircleOutlineRounded className={color} />}
                                </Grid>
                                <Grid item xs={10}>
                                    <Truncate lines={1} ellipsis={<span>...</span>} >
                                        {plan?.availableTeachersTeacherTrainings+" "+ plan?.teacherTeacherTrainingsTitle}
                                    </Truncate>
                                </Grid>
                            </Grid>
                         */}
                <Grid item xs={10} container>
                  <Grid item xs={2}>
                    <CheckCircleOutlineRounded
                      className={color}
                      style={{ color: !plan?.extras && "#fff" }}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    {plan?.extras && (
                      <Truncate lines={1} ellipsis={<span>...</span>}>
                        {plan?.extras}
                      </Truncate>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={10} className={classes.purchaseContainer}>
                <Link
                  className={clsx(classes.link)}
                  to={
                    courseId
                      ? `${RELATE_APP_BASE_URL}/view-course/${courseId}?tab=signup`
                      : `${RELATE_APP_BASE_URL}/more/subscription-plan`
                  }
                  target="_blank"
                >
                  <Button
                    aria-label="Subscribe Now"
                    className={clsx(classes.purchaseButton, background)}
                  >
                    <Typography className={classes.btnTxt}>
                      {"Subscribe Now"}
                    </Typography>
                  </Button>
                </Link>
              </Grid>

              <Grid item xs={11} className={classes.warningContainer}>
                {plan?.subscriptionDetailText && (
                  <Truncate
                    lines={2}
                    ellipsis={<span>...</span>}
                    style={{ color: "#1d00ff" }}
                  >
                    <Typography
                      variant="h2"
                      className={clsx(classes.warningTxt)}
                    >
                      {plan?.subscriptionDetailText}
                    </Typography>
                  </Truncate>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ) : (
        ""
      )}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    // width: '100%',
    color: "#000",
    background: "#fff",
    borderRadius: 15,
    width: 300,
    maxWidth: "75vw",
  },
  header: {
    height: "72px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  headerTxt: {
    color: "#FFFFFF",
    textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "115%",
  },
  iconContainer: {
    background: "#FFf !important",
    marginRight: 10,
    height: 30,
    width: 30,
  },
  tagClr: {
    background: "#1cb22e",
  },
  tagStyle: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: "100%",
  },
  priceContainer: {
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderBottom: "1px solid #D6D6D6",
  },
  freeTxt: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "48px",
    lineHeight: "115%",
    color: "#797979",
  },
  priceTxt: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "36px",
    lineHeight: "115%",
    color: "#797979",
    marginRight: 3,
    textDecoration: "line-through",
  },
  priceDesTxt: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "115%",
    color: "#fff",
  },
  limitTxt: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "10px",
    lineHeight: "115%",
    color: "#C4C4C4",
    marginTop: 3,
  },
  discountPriceTxt: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 600,
    /* fontSize: "36px", */
    lineHeight: "115%",
    // color: '#0DBCC7',
    marginLeft: 3,
  },
  priceDescriptionContainer: {
    height: 30,
    width: "70%",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    borderRadius: 5,
  },
  benefitsTxt: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: 19,
    color: "#393939",
  },
  benefitContainer: {
    marginTop: "4px",
    height: "auto",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    padding: "20px 0px 10px 0px",
    // overflowY: 'auto',
    // "&::-webkit-scrollbar": {
    //     display: "none",
    // },
  },
  purchaseContainer: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    height: "38px",
  },
  warningContainer: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    height: "58px",
  },
  purchaseButton: {
    width: "100%",
    height: "38px",
    borderRadius: "66px",

    textTransform: "none",
  },
  btnTxt: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    color: "#fff",
  },
  warningTxt: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    marginTop: 5,
    color: "#1d00ff",
  },

  modelBtn: {
    fontFamily: "Roboto",
    cursor: "pointer",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "21px",
    textTransform: "none",
    color: "#fff",
    background: `linear-gradient(#09A9BB 1%,#09A9BB 100%)`,
  },
  cancel: {
    color: "#FF0000",
    textAlign: "center",
    cursor: "pointer",
  },
  crossIcon: {
    color: "#FF0000",
  },
  link: {
    width: "100%",
    textDecoration: "none",
    height: "50px",
  },
}));
